<template>
  <div>
    <v-container fluid class="layout-content pa-0 w-full">
      <v-card class="text-center pa-0" elevation="0">
        <v-card-text class="pa-0">
          <v-row>
            <v-col
              offset-lg="3"
              lg="6"
              offset-md="3"
              md="6"
              offset-sm="3"
              sm="6"
              cols="12"
              :align-self="'center'"
            >
              <div class="d-flex display-1 text-start">
                ข้อมูลทั่วไป

                <v-spacer></v-spacer>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.push({ name: 'new-lead' })"
                    >
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                  </template>
                  <span>ย้อนกลับ</span>
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="disabled = false"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>แก้ไขข้อมูล</span>
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="handleLogout">
                      <v-icon>mdi-logout-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>ออกจากระบบ</span>
                </v-tooltip>
              </div>
              <br />
              <v-row>
                <v-col cols="12" md="6" lg="6">
                  <v-text-field
                    :readonly="disabled"
                    outlined
                    label="First name"
                    v-model="getAgentInfo.user.first_name"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <v-text-field
                    :readonly="disabled"
                    outlined
                    label="Last name"
                    v-model="getAgentInfo.user.last_name"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <v-text-field
                    :readonly="disabled"
                    outlined
                    label="ชื่อ ไทย"
                    v-model="getAgentInfo.th_firstname"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <v-text-field
                    :readonly="disabled"
                    outlined
                    label="นามสกุล ไทย"
                    v-model="getAgentInfo.th_lastname"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <v-text-field
                    :readonly="disabled"
                    outlined
                    label="เบอร์โทรศัพท์"
                    v-model="getAgentInfo.tel"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <v-text-field
                    readonly
                    outlined
                    label="อีเมล"
                    hint="Read only"
                    v-model="getAgentInfo.user.email"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <v-text-field
                    :readonly="disabled"
                    outlined
                    label="Line ID"
                    v-model="getAgentInfo.line_user_ID"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <div class="text-end">
                <v-spacer></v-spacer>
                <v-btn
                  v-show="!disabled"
                  color="success"
                  class="mx-2"
                  @click="handleUpdateInfo"
                >
                  Save
                </v-btn>
              </div>
            </v-col>

            <v-col
              offset-lg="3"
              lg="6"
              offset-md="3"
              md="6"
              offset-sm="3"
              sm="6"
              cols="12"
              :align-self="'center'"
            >
              <div class="d-flex display-1 text-start">แก้ไขรหัสผ่าน</div>
              <br />
              <div>
                <v-row>
                  <v-col cols="12">
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-text-field
                        v-model="oldPassword"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        label="Old Password"
                        required
                        outlined
                        :rules="[(v) => !!v || 'กรุณากรอกข้อมูล']"
                        @click:append="show1 = !show1"
                      ></v-text-field>
                      <v-text-field
                        v-model="newPassword"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="nameRules"
                        :type="show2 ? 'text' : 'password'"
                        label="New Password"
                        required
                        outlined
                        @click:append="show2 = !show2"
                      ></v-text-field>

                      <v-text-field
                        v-model="confirmPassword"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="emailRules"
                        :type="show2 ? 'text' : 'password'"
                        label="อัพเดทรหัสผ่าน"
                        @click:append="show2 = !show2"
                        required
                        outlined
                      ></v-text-field>
                    </v-form>
                    <div class="text-center">
                      <v-btn
                        :readonly="!valid"
                        color="success"
                        class="mx-2"
                        @click="validate"
                        :loading="isLoading"
                        :disabled="isLoading"
                      >
                        อัพเดทรหัสผ่าน
                      </v-btn>
                      <!-- <v-btn class="mx-2" fab dark small color="primary">
                        <v-icon dark>mdi-minus</v-icon>
                      </v-btn>

                      <v-btn class="mx-2" fab dark small color="pink">
                        <v-icon dark>mdi-heart</v-icon>
                      </v-btn>

                      <v-btn class="mx-2" fab dark color="indigo">
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                      -->

                      <!-- <v-btn color="error" class="mr-4" @click="reset">
                      Reset Form
                    </v-btn>

                    <v-btn color="warning" @click="resetValidation">
                      Reset Validation
                    </v-btn> -->
                    </div>
                    <br />
                  </v-col>
                </v-row>
              </div>
              <v-container class="py-8">
                <div
                  class="
                    d-flex
                    flex-column flex-lg-row
                    justify-space-between
                    align-center
                  "
                >
                  <div class="text-center text-lg-left">
                    <div class="text-h4 primary--text">แจ้งปัญหาการใช้งาน</div>
                    <div
                      :class="[
                        $vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h6',
                      ]"
                      class=""
                    >
                      หรือ สอบถามข้อมูลเพิ่มเติม
                    </div>
                  </div>
                  <div class="mt-4 mt-lg-0">
                    <div
                      class="
                        my-1
                        mx-sm-2
                        w-full w-sm-auto
                        text-subtitle-1
                        font-weight-black
                      "
                    >
                      Email: support@cartrust.co.th
                    </div>
                  </div>
                </div>
              </v-container>
            </v-col></v-row
          ></v-card-text
        >
      </v-card>
      <!-- Snackbar (Visual notification) -->
      <div class="text-center">
        <v-snackbar
          v-model="snackBarAlert.alert"
          :middle="true"
          :color="snackBarAlert.snackBarColor"
          absolute:timeout="3"
        >
          {{ snackBarAlert.snackBarText }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackBarAlert.alert = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <!-- End Snackbar (Visual notification) -->
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  data: () => ({
    isLoading: false,
    disabled: true,
    show1: false,
    show2: false,
    show3: false,
    snackBarAlert: {
      alert: false,
      snackBarText: "",
      snackBarColor: null,
    },
    valid: true,
    error: false,
    errorMessages: "",
    name: "",
    nameRules: [
      (v) => !!v || "กรุณากรอกข้อมูล",
      (v) => (v && v.length >= 8) || "Password must be at least 8 Characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "กรุณากรอกข้อมูล",
      (v) => (v && v.length >= 8) || "Password must be at least 8 Characters",
    ],
    select: null,
    checkbox: false,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    tab: null,
    breadcrumbs: [
      {
        text: "Users",
        to: "/users/list",
        exact: true,
      },
      {
        text: "Edit User",
      },
    ],
  }),

  computed: {
    ...mapGetters("agent", ["getAgentInfo"]),
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    ...mapActions("agent", [
      "fetchUserProfile",
      "changeUserPassword",
      "logoutAgent",
      "updateAgentInfo",
    ]),
    handleUpdateInfo() {
      this.updateAgentInfo(this.getAgentInfo).then(() => {
        this.snackBarAlert.alert = true;
        this.snackBarAlert.snackBarText = "ข้อมูลถูกอัพเดท";
        this.snackBarAlert.snackBarColor = "success";
        setTimeout(() => {
          this.snackBarAlert.alert = false;
        }, 2000);
      });
    },
    handleLogout() {
      this.logoutAgent().then(() => this.$router.push("/"));
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        if (this.newPassword !== this.confirmPassword) {
          this.snackBarAlert.alert = true;
          this.snackBarAlert.snackBarText = "ยืนยันรหัสผ่านใหม่ไม่ถูกต้อง";
          this.snackBarAlert.snackBarColor = "error";
          this.isLoading = false;

          setTimeout(() => {
            this.snackBarAlert.alert = null;
            this.snackBarAlert.snackBarText = "";
            this.snackBarAlert.snackBarColor = null;
          }, 2000);
        } else {
          this.changeUserPassword({
            user: this.getAgentInfo,
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          })
            .then(() => {
              this.snackBarAlert.alert = true;
              this.snackBarAlert.snackBarText = "Password updated";
              this.snackBarAlert.snackBarColor = "success";
              this.isLoading = false;

              setTimeout(() => {
                this.$router.push({
                  name: "new-lead",
                });
              }, 2000);
            })
            .catch((err) => {
              console.log("err", err.response);
              this.snackBarAlert.alert = true;
              this.snackBarAlert.snackBarText =
                err.response.data.detail || "Can not change password";
              this.snackBarAlert.snackBarColor = "error";
              this.isLoading = false;

              setTimeout(() => {
                this.snackBarAlert.alert = null;
                this.snackBarAlert.snackBarText = "";
                this.snackBarAlert.snackBarColor = null;
              }, 2000);
            });
        }
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
